.radius-3 {
	border-radius: 3px !important;
}
.radius-5 {
	border-radius: 5px !important;
}
.cureved--bg.bg--light {
	background: transparent !important;
	position: relative;

	&::before {
		@extend %position-set;
		background: hsl(var(--light));
	}
	.container {
		position: relative;
		z-index: 1;
	}
}
.rating {
	&.color {
		color: #e2cc00;
	}
}

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background: #fff;
}

/* line 10, src/assets/scss/components/_components.preloader.scss */
.preloader .preloader-wrap {
	display: block;
	position: relative;
	text-align: center;
	top: 40%;
}

/* line 15, src/assets/scss/components/_components.preloader.scss */
.preloader .preloader-wrap .preloader {
	position: absolute;
	left: 51%;
	margin-left: -35px;
	height: 60px;
	width: 70px;
	overflow: hidden;
	top: 40px;
}

/* line 23, src/assets/scss/components/_components.preloader.scss */
.preloader .preloader-wrap .preloader i {
	display: block;
	width: 20px;
	height: 20px;
	background: #1a202c;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -8px 0 0 -8px;
	opacity: 1;
	-webkit-transform: translate3d(60px, 0, 0);
	overflow: hidden;
	text-indent: -9999px;
	border: 2px solid #fff;
}

/* line 39, src/assets/scss/components/_components.preloader.scss */
.preloader .preloader-wrap .preloader i:nth-child(1) {
	background: #0948b3;
	-webkit-animation: viserhost 1.75s ease-in-out infinite;
	animation: viserhost 1.75s ease-in-out infinite;
}

/* line 47, src/assets/scss/components/_components.preloader.scss */
.preloader .preloader-wrap .preloader i:nth-child(2) {
	background: #1062fe;
	-webkit-animation: viserhost 1.75s ease-in-out infinite 0.3s;
	animation: viserhost 1.75s ease-in-out infinite 0.3s;
}

/* line 55, src/assets/scss/components/_components.preloader.scss */
.preloader .preloader-wrap .preloader i:nth-child(3) {
	background: #f67a3c;
	-webkit-animation: viserhost 1.75s ease-in-out infinite 0.6s;
	animation: viserhost 1.75s ease-in-out infinite 0.6s;
}

@-webkit-keyframes viserhost {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(60px, 0, 0);
		transform: translate3d(60px, 0, 0);
	}

	30% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	70% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translate3d(-300px, 0, 0);
		transform: translate3d(-300px, 0, 0);
	}
}

@keyframes viserhost {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(60px, 0, 0);
		transform: translate3d(60px, 0, 0);
	}

	30% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	70% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translate3d(-300px, 0, 0);
		transform: translate3d(-300px, 0, 0);
	}
}
// Section Header Starts
.section-header {
	margin-bottom: 50px;
	@include breakpoint(max-lg) {
		margin-bottom: 45px;
	}
	@include breakpoint(max-md) {
		margin-bottom: 40px;
	}
	&__title {
		margin-bottom: 15px;
		font-weight: 500;
	}
	.text {
		margin-bottom: 15px;
		font-size: 18px;
		@include breakpoint(max-xl) {
			font-size: 17px;
		}
	}
	&.text-white {
		.section-header__title {
			color: hsl(var(--white));
		}
		.text {
			max-width: 550px;
		}
	}
	&.text-center {
		.text {
			max-width: 500px;
			margin: 0 auto;
		}
	}
}
.section-thumb {
	position: relative;
	img {
		max-width: 100%;
	}
	.shape1 {
		position: absolute;
		bottom: -50px;
		right: 0px;
	}
}
// Section Header Ends

.social-links {
	gap: 5px 8px;
	&.social-base1 {
		li {
			a {
				&::before {
					background: var(--gradient-one);
				}
			}
		}
	}
	&.social-base2 {
		li {
			a {
				&::before {
					background: var(--gradient-two);
				}
			}
		}
	}
	li {
		a {
			width: 40px;
			height: 40px;
			background: hsl(var(--white));
			position: relative;
			border-radius: 50%;
			display: grid;
			place-items: center;
			&::before {
				@extend %position-set;
				border-radius: 50%;
				opacity: 0;
				@extend %transition;
			}
			&:hover,
			&.active {
				i {
					color: hsl(var(--white));
					position: relative;
					z-index: 1;
				}
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}
// Feature Section Starts
.feature-item {
	padding: 65px 40px;
	background: hsl(var(--light));
	@extend %transition;
	&:hover {
		background: hsl(var(--light-600));
	}
	@include breakpoint(max-xxl) {
		padding: 40px 30px;
	}
	@include breakpoint(max-xxl) {
		padding: 30px 20px;
	}
	&__icon {
		margin-bottom: 25px;
		img {
			width: 80px;
			height: 80px;
			object-fit: contain;
			@include breakpoint(max-xl) {
				width: 65px;
				height: 65px;
			}
		}
	}
	&__content {
		.title {
			margin-bottom: 20px;
		}
	}
	&.style-two {
		padding-top: 0 !important;
		margin-top: 40px;
		border-radius: 10px;
		text-align: center;
		padding-bottom: 50px;
		@include breakpoint(max-xl) {
			@include breakpoint(lg) {
				padding: 15px;
				padding-bottom: 25px;
			}
		}
		.feature-item__icon {
			width: 100px;
			height: 100px;
			margin: 0 auto;
			border: 10px solid hsl(var(--white));
			border-radius: 50%;
			background: var(--gradient-one);
			display: grid;
			place-items: center;
			transform: translateY(-50%);
			margin-bottom: -20px;
			@include breakpoint(max-xl) {
				margin-bottom: -40px;
			}
			img {
				width: 40px;
				height: 40px;
				object-fit: contain;
			}
		}
	}
}
// Feature Section Ends

// Statistic Section Starts
.statistic-item {
	@extend %flex;
	&__icon {
		img {
			width: 80px;
			height: 80px;
			object-fit: contain;
			@include breakpoint(max-xl) {
				width: 65px;
				height: 65px;
			}
		}
	}
	&__content {
		padding-left: 25px;
		width: calc(100% - 80px);
		.title {
			font-size: 40px;
			color: rgb(var(--base1));
			line-height: 1;
			@include breakpoint(max-xl) {
				font-size: 36px;
			}
		}
		p {
			font-size: 22px;
			color: var(--heading);
			@include breakpoint(max-xl) {
				font-size: 18px;
			}
		}
	}
}
// Statistic Section Ends

// Overview Section Starts
.overview-left {
	width: 60%;
	@include breakpoint(max-lg) {
		width: 100%;
	}
}
.overview-right {
	width: 40%;
	@include breakpoint(max-lg) {
		width: 100%;
	}
}
.container-max-lg {
	@include breakpoint(max-lg) {
		max-width: 720px;
		margin: 0 auto;
		padding-inline: 15px;
	}
	@include breakpoint(max-md) {
		max-width: 540px;
	}
}
.service-item {
	padding: 45px 30px;
	border: 1px solid hsl(var(--border));
	border-radius: 10px;
	@include breakpoint(max-xxl) {
		@include breakpoint(xl) {
			padding: 30px 20px;
		}
	}
	&__icon {
		img {
			width: 35px;
			height: 35px;
			object-fit: contain;
		}
	}
	&__content {
		margin-top: 20px;
		.title {
			margin-bottom: 10px;
		}
	}
}
.service-item-two {
	&__header {
		display: flex;
	}
	.service-icon {
		width: 60px;
		height: 60px;
		display: grid;
		place-items: center;
		border-radius: 50%;
		background: var(--gradient-one);
		flex-shrink: 0;
		img {
			max-width: 25px;
		}
	}
	.title {
		flex-grow: 1;
		padding-left: 18px;
	}
	&.style-two {
		padding: 40px;
		border-radius: 15px;
		background: hsl(var(--light));
		@include breakpoint(max-sm) {
			padding: 15px;
		}
	}
}
.how-item {
	@extend %flex;
	margin-bottom: 45px;
	max-width: 460px;
	@include breakpoint(max-lg) {
		margin-bottom: 30px;
	}
	&__icon {
		width: 70px;
		height: 70px;
		color: hsl(var(--white));
		font-size: 24px;
		background: var(--gradient-one);
		display: grid;
		place-items: center;
		border-radius: 50%;
		@include breakpoint(max-xs) {
			width: 55px;
			height: 55px;
			font-size: 20px;
		}
	}
	&__content {
		padding-left: 25px;
		width: calc(100% - 70px);
		@include breakpoint(max-xs) {
			width: calc(100% - 55px);
			padding-left: 18px;
		}
		p {
			color: var(--heading);
			font-size: 24px;
			line-height: 1.3;
			@include breakpoint(max-xxl) {
				font-size: 20px;
			}
			@include breakpoint(max-xs) {
				font-size: 17px;
			}
		}
	}
}
.how-item-two {
	@include breakpoint(xl) {
		padding-right: 15px;
	}
	@include breakpoint(xxl) {
		padding-right: 25px;
	}
	.how-item__thumb {
		img {
			width: 150px;
			height: 150px;
			object-fit: contain;
			margin-bottom: 25px;
		}
	}
	.how-item__icon {
		width: 80px;
		height: 80px;
		margin-bottom: 25px;
	}
	.how-item__content {
		padding-left: 0;
		width: 100%;
		.title {
			margin-bottom: 15px;
		}
		p {
			font-size: 16px;
			line-height: 1.9;
			@include breakpoint(max-lg) {
				color: hsl(var(--body));
			}
		}
	}
}
.section-content {
	.header-contact {
		padding: 22px 50px;
		font-size: 26px;
		letter-spacing: 1px;
		@include breakpoint(max-xl) {
			padding: 22px 40px;
			font-size: 20px;
		}
		@include breakpoint(max-lg) {
			text-align: center;
			width: 50%;
			padding: 15px 20px;
		}
		@include breakpoint(max-md) {
			width: 100%;
		}
	}
}
// Overview Section Ends

// Plan Section Starts
.plan-item {
	text-align: center;
	border-radius: 23px 23px 10px 10px;
	position: relative;
	.plan-inner {
		border: 2px solid rgb(var(--base1) / 0.8);
		margin: 0 30px;
		margin-top: -35px;
		border-radius: 0 0 10px 10px;
		@include breakpoint(max-xxl) {
			margin: 0 25px;
			margin-top: -35px;
		}
		@include breakpoint(max-xs) {
			margin: 0 10px;
			margin-top: -35px;
		}
	}
	&__header {
		padding: 60px 30px 55px;
		border-radius: 20px;
		-webkit-mask-image: url(./../images/shape/rectangle.png);
		mask-image: url(./../images/shape/rectangle.png);
		-webkit-mask-size: 100% 100%;
		mask-size: 100% 100%;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		background: var(--gradient-one);
		@include breakpoint(max-xxl) {
			padding: 35px 25px 50px;
		}
		.plan-name {
			color: hsl(var(--white));
			font-size: 40px;
			font-family: var(--heading);
			@include breakpoint(max-xxl) {
				font-size: 30px;
			}
		}
		.plan-price {
			color: hsl(var(--white));
			font-size: 30px;
			sub {
				bottom: 0;
				font-weight: 300;
				font-size: 65%;
			}
			@include breakpoint(max-xxl) {
				font-size: 22px;
			}
		}
	}
	&__body {
		padding: 70px 50px 60px;
		@include breakpoint(max-xxl) {
			padding: 65px 40px 40px;
		}
		@include breakpoint(max-lg) {
			padding: 65px 30px 35px;
		}
		.list {
			text-align: left;
		}
		.btn {
			margin-top: 45px;
			padding: 14px 35px;
			@include breakpoint(max-xxl) {
				margin-top: 30px;
			}
		}
	}
	.plan-status {
		position: absolute;
		color: hsl(var(--white));
		background: rgb(var(--base3));
		display: inline-block;
		padding: 7px 25px;
		padding-left: 60px;
		font-weight: 700;
		border-radius: 10px;
		border: 1px dashed hsl(var(--white));
		outline: 5px solid rgb(var(--base3));
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		font-size: 14px;
		@include breakpoint(max-xxl) {
			padding: 6px 25px;
			padding-left: 60px;
		}
		&::before {
			position: absolute;
			content: "";
			left: -20px;
			top: 12px;
			background: linear-gradient(270.75deg, #ffdf00 0.04%, #ffb100 99.08%);
			height: 20px;
			width: 75px;
			border-radius: 15px 0 0 0;
			z-index: 3;
			clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
			@include breakpoint(max-xxl) {
				top: 10px;
			}
		}
		&::after {
			position: absolute;
			content: "";
			left: -20px;
			top: 12px;
			width: 15px;
			height: 28px;
			background: linear-gradient(270.75deg, #ffdf00 0.04%, #ffb100 99.08%);
			border-radius: 35px 0 0 10px;
			z-index: 2;
			@include breakpoint(max-xxl) {
				top: 10px;
			}
		}
		sub {
			left: -5px;
			z-index: 4;
			bottom: 18px;
			position: absolute;
			color: hsl(var(--dark));
		}
	}
}
div[class*="col"] {
	&:nth-of-type(3n + 1) {
		.plan-item__header {
			background: var(--gradient-one);
		}
		.plan-inner {
			border-color: rgb(var(--base1));
			.plan-status {
				background: rgb(var(--base1));
				outline-color: rgb(var(--base1));
			}
		}
		.plan-item__body {
			.list {
				li::before {
					background: var(--gradient-one);
				}
			}
			.btn {
				border-color: rgb(var(--base1));
				color: rgb(var(--base1));
			}
		}
		.plan-item-two {
			.discount::before {
				position: absolute;
				content: "";
				width: 100px;
				height: 100px;
				background: url(./../images/shape/plan1.png) no-repeat;
				background-size: contain;
				right: 0;
				top: 0;
				@include breakpoint(max-lg) {
					display: none;
				}
			}
			.list {
				li::before {
					background: var(--gradient-one);
				}
			}
			.plan-price {
				background: var(--gradient-one);
			}
			.plan-btn {
				&::before {
					background: var(--gradient-one);
				}
			}
		}
	}
	&:nth-of-type(3n + 2) {
		.plan-item__header {
			background: var(--gradient-two);
		}
		.plan-inner {
			border-color: rgb(var(--base2));
			.plan-status {
				background: rgb(var(--base2));
				outline-color: rgb(var(--base2));
			}
		}
		.plan-item__body {
			.list {
				li::before {
					background: var(--gradient-two);
				}
			}
			.btn {
				border-color: rgb(var(--base2)) !important;
				color: rgb(var(--base2)) !important;
				&:hover {
					color: hsl(var(--white)) !important;
					background: rgb(var(--base2)) !important;
				}
			}
		}
		.plan-item-two {
			.discount::before {
				position: absolute;
				content: "";
				width: 100px;
				height: 100px;
				background: url(./../images/shape/plan2.png) no-repeat;
				background-size: contain;
				right: 0;
				top: 0;
				@include breakpoint(max-lg) {
					display: none;
				}
			}
			.list {
				li::before {
					background: var(--gradient-two);
				}
			}
			.plan-price {
				background: var(--gradient-two);
			}
			.plan-btn {
				&::before {
					background: var(--gradient-two);
				}
			}
		}
	}
	&:nth-of-type(3n + 3) {
		.plan-item__header {
			background: var(--gradient-three);
		}
		.plan-inner {
			border-color: rgb(var(--base3));
			.plan-status {
				background: rgb(var(--base3));
				outline-color: rgb(var(--base3));
			}
		}
		.plan-item__body {
			.list {
				li::before {
					background: var(--gradient-three);
				}
			}
			.btn {
				border-color: rgb(var(--base3)) !important;
				color: rgb(var(--base3)) !important;
				&:hover {
					color: hsl(var(--white)) !important;
					background: rgb(var(--base3)) !important;
				}
			}
		}
		.plan-item-two {
			.discount::before {
				position: absolute;
				content: "";
				width: 100px;
				height: 100px;
				background: url(./../images/shape/plan3.png) no-repeat;
				background-size: contain;
				right: 0;
				top: 0;
				@include breakpoint(max-lg) {
					display: none;
				}
			}
			.list {
				li::before {
					background: var(--gradient-three);
				}
			}
			.plan-price {
				background: var(--gradient-three);
			}
			.plan-btn {
				&::before {
					background: var(--gradient-three);
				}
			}
		}
	}
}

.plan-item-two {
	border-radius: 15px;
	background: hsl(var(--light));
	position: relative;
	.discount {
		color: hsl(var(--white));
		font-size: 13px;
		font-weight: 500;
		span {
			position: absolute;
			right: 18px;
			top: 45px;
			transform: rotate(45deg);
			z-index: 1;
			@include breakpoint(max-lg) {
				padding: 3px 15px;
				border-radius: 25px;
				border: 1px solid hsl(var(--white));
				line-height: 1;
				right: -5px;
				top: 45px;
			}
			@include breakpoint(max-xs) {
				right: -10px;
				top: 40px;
				font-size: 12px;
			}
		}
	}
	.plan-inner {
		overflow: hidden;
		border-radius: 15px;
	}
	&__body {
		padding: 40px 50px;
		@include breakpoint(max-xs) {
			padding: 40px 30px;
		}
	}
	.plan-btn {
		padding: 35px 0;
		display: block;
		position: relative;
		z-index: 1;
		color: hsl(var(--white));
		font-size: 18px;
		font-weight: 500;
		&::before {
			position: absolute;
			content: "";
			width: 150%;
			aspect-ratio: 1;
			background: var(--gradient-one);
			bottom: 0;
			border-radius: 50%;
			left: 50%;
			top: 0;
			transform: translate(-50%, 0%);
			z-index: -1;
		}
	}
	.plan-price {
		color: hsl(var(--white));
		background: var(--gradient-one);
		text-align: center;
		padding: 45px 18px;
		display: inline-block;
		border-radius: 10px 10px 0 0;
		width: 100%;
		padding: 40px 20px;
		font-size: 26px;
	}
}
.plan-item-three {
	@extend .plan-item-two;
	padding: 60px 40px;
	padding-top: 0;
	margin: 0;
	margin-top: 25px;
	@include breakpoint(max-xxl) {
		padding: 40px 30px;
		padding-top: 0;
	}

	&__header {
		text-align: left;
	}
	.btn {
		margin-top: 30px;
	}
	.price {
		background: var(--gradient-one);
		text-align: center;
		padding: 30px 25px;
		border-radius: 10px;
		color: hsl(var(--white));
		transform: translateY(-50%);
		@include breakpoint(max-xxl) {
			font-size: 20px;
		}
	}
	&.style-two {
		padding: 40px;
		margin-top: 0;
		.price {
			transform: translate(0);
			background: transparent;
			padding: 0;
			color: var(--heading);
			text-align: left;
			@include breakpoint(max-xxl) {
				font-size: 40px;
			}
			sub,
			sup {
				font-size: 45%;
				font-weight: 300;
			}
			sub {
				bottom: 0;
			}
			sup {
				top: -20px;
			}
		}
		.btn {
			margin: 45px 0;
		}
		.plan-item-three__footer {
			padding-top: 30px;
			position: relative;
			border-top: 1px solid hsl(var(--border));
		}
	}
}
.vps-plan {
	text-align: center;
	.title {
		padding: 15px;
		background: var(--gradient-one);
		border-radius: 10px 10px 0 0;
	}
	.content {
		background: hsl(var(--light));
		border-radius: 0 0 10px 10px;
		padding: 15px;
	}
}
.plan-slider {
	padding: 10px 25px;
	background: var(--gradient-one);
	border-radius: 35px;
}
// Plan Section Ends

// Plan Overview Section Starts
.plan-tab {
	justify-content: center;
	gap: 24px;
	li {
		@include breakpoint(max-sm) {
			width: 100%;
		}
		a {
			border: 1px solid hsl(var(--border));
			border-radius: 8px;
			padding: 20px 40px;
			@include breakpoint(max-md) {
				padding: 12px 25px;
			}
			@include breakpoint(max-sm) {
				padding: 12px 25px;
				display: block;
			}
			img {
				margin-right: 15px;
			}
			&.active {
				background: hsl(var(--accent));
				color: hsl(var(--white));
			}
		}
	}
}
// Plan Overview Section Ends

// Team Section Starts
.team-item {
	text-align: center;
	&__thumb {
		position: relative;
		display: flex;
		align-items: center;
		border-radius: 5px;
		.social-links {
			position: absolute;
			width: 100%;
			height: auto;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			li {
				transform: scale(0.4);
				@extend %transition;
				opacity: 0;
				visibility: hidden;
				&:nth-child(1) {
					transition-delay: 0.1s;
				}
				&:nth-child(2) {
					transition-delay: 0.2s;
				}
				&:nth-child(3) {
					transition-delay: 0.3s;
				}
				&:nth-child(4) {
					transition-delay: 0.4s;
				}
			}
		}
		img {
			width: 100%;
			border-radius: 5px;
		}
		&::before {
			@extend %position-set;
			background: radial-gradient(108.3% 186.75% at -4.62% 9.78%, #f9636bd2 0%, #b6246e 100%);
			border-radius: 5px;
			opacity: 0;
			visibility: hidden;
			@extend %transition;
		}
	}
	&__content {
		padding-top: 25px;
		@include breakpoint(max-xl) {
			padding-top: 15px;
		}
	}
	&:hover {
		.team-item__thumb {
			&::before {
				visibility: visible;
				opacity: 1;
			}
			.social-links {
				li {
					transform: scale(1);
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
	&.style-two {
		.team-item__thumb {
			overflow: hidden;
			border-radius: 250px 250px 0 0;
			&::before {
				border-radius: 250px 250px 0 0;
				height: 150px;
				top: auto;
				bottom: 0;
				transform: translateY(100%);
			}
			.social-links {
				top: auto;
				bottom: 15px;
			}
		}
		&:hover {
			.team-item__thumb::before {
				transform: translateY(0%);
			}
		}
	}
}
// Team Section Ends

// Why Choose Section Starts
.choose-item {
	&__header {
		@extend %flex;
		align-items: center;
		margin-bottom: 20px;
		@include breakpoint(max-lg) {
			margin-bottom: 15px;
		}
		.icon {
			img {
				width: 45px;
				height: 45px;
				object-fit: contain;
			}
		}
		.title {
			padding-left: 20px;
			width: calc(100% - 45px);
		}
	}
}
// Why Choose Section Ends

// Testimonial Section Starts
.testimonial-section {
	position: relative;
	.shape {
		position: absolute;
		top: 0;
		opacity: 0.04;
		z-index: -1;
		max-width: 100%;
		img {
			max-width: 100%;
		}
		&.shape1 {
			left: 0;
		}
		&.shape2 {
			right: 0;
		}
	}
}
.testimonial-wrapper {
	max-width: 720px;
	margin: 0 auto;
	text-align: center;
	.name,
	.designation {
		@extend %transition;
	}
	.thumb-slider {
		img {
			width: 120px;
			height: 120px;
			margin: 0 auto 15px;
			object-fit: cover;
		}
	}
	.content-slider {
		.quote-icon {
			font-size: 36px;
			color: rgb(var(--base1));
			line-height: 1;
			margin-top: 20px;
		}
		p {
			font-weight: 500;
			font-size: 20px;
			@include breakpoint(max-md) {
				font-size: 18px;
			}
			@include breakpoint(max-sm) {
				font-size: 16px;
			}
		}
	}
	.thumb-item {
		img {
			border-radius: 50%;
		}
	}
}
.content-slider {
	padding-bottom: 30px;
}
.swiper-pagination {
	bottom: 0px !important;
	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
	}
	.swiper-pagination-bullet-active {
		background-color: rgb(var(--base1));
	}
}
.testimonial-item-two {
	padding: 50px 40px;
	border-radius: 5px;
	@include breakpoint(max-xs) {
		padding: 25px 20px;
	}
	&__header {
		.thumb {
			position: relative;
			img {
				width: 80px;
				height: 80px;
				object-fit: cover;
				border-radius: 50%;
			}
			.quote-icon {
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				font-size: 36px;
				color: rgb(var(--base1));
				line-height: 1;
			}
		}
	}
}
// Testimonial Section Ends

// Blog Section Starts
.post-item {
	border-radius: 5px;
	overflow: hidden;
	&:hover {
		.title a {
			color: rgb(var(--base2));
		}
		.post-item__thumb img {
			transform: scale(1.1);
		}
	}
	&__thumb {
		overflow: hidden;
		img {
			width: 100%;
		}
	}
	&__content {
		padding: 30px;
		@include breakpoint(max-xl) {
			padding: 20px;
		}
		.title a {
			margin-bottom: 12px;
			@extend %text-wrap;
			-webkit-line-clamp: 2;
		}
		p {
			margin-top: 12px;
			@extend %text-wrap;
			-webkit-line-clamp: 3;
		}
		.read-more {
			font-weight: 500;
			font-size: 18px;
			margin-top: 5px;
			&:hover {
				color: rgb(var(--base2));
			}
		}
	}
	&.style-two {
		position: relative;
		&::before {
			@extend %position-set;
			height: 2px;
			top: auto;
			bottom: 0;
			background: var(--gradient-one);
		}
	}
}
.post-meta {
	@extend %flex;
	gap: 10px 25px;
	@include breakpoint(max-md) {
		font-size: 14px;
	}
	i {
		margin-right: 5px;
		font-size: 17px;
		color: rgb(var(--base2));
	}
}
.blog-details {
	p {
		-webkit-line-clamp: unset;
	}
	.blockquote {
		background: var(--gradient-one);
		border-radius: 10px;
		.quote-icon {
			font-size: 50px;
			line-height: 1;
			margin-top: 0;
			opacity: 0.3;
		}
		@include breakpoint(max-xl) {
			font-size: 16px;
		}
	}
	.post-item__content {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(max-sm) {
			padding-top: 35px;
			padding-bottom: 35px;
		}
	}
}
.recent-post-item {
	display: flex;
	&:not(:last-child) {
		margin-bottom: 45px;
		@include breakpoint(max-xl) {
			margin-bottom: 15px;
		}
	}
	.thumb {
		flex-shrink: 0;
		img {
			width: 110px;
			height: 80px;
			object-fit: cover;
		}
	}
	.content {
		flex-grow: 1;
		padding-left: 12px;
		.date {
			@include breakpoint(max-xl) {
				font-size: 14px;
			}
		}
		.date i {
			color: rgb(var(--base2));
		}
		.title {
			font-size: 18px;
			a {
				@extend %text-wrap;
				-webkit-line-clamp: 2;
			}
			@include breakpoint(max-xl) {
				font-size: 16px;
			}
		}
	}
}
// Blog Section Ends

.domain-search-section {
	.shape {
		position: absolute;
		right: 70%;
		top: 50%;
		transform: translateY(-40%);
		img {
			max-width: 550px;
		}
	}
}
.domain-search-wrapper {
	padding: 80px 40px;
	border-radius: 15px;
	background: hsl(var(--light));
}
// Brand Section Starts
.brand-item {
	background: hsl(var(--accent));
	display: grid;
	place-items: center;
	padding: 20px;
	border-radius: 10px;
	@include breakpoint(xl) {
		min-height: 120px;
	}
	@include breakpoint(max-lg) {
		padding: 12px;
	}
	img {
		max-width: 100%;
	}
}
// Brand Section Ends

.cmn--tabs {
	gap: 10px;
	li {
		a {
			border: 1px solid hsl(var(--border));
			padding: 8px 20px;
			border-radius: 5px;
			&.active {
				background: var(--gradient-one);
				color: hsl(var(--white));
			}
		}
	}
}

.map-wrapper-two {
	height: 100%;
	padding-top: 40px;
	padding-right: 40px;
	position: relative;
	@include breakpoint(max-sm) {
		padding-right: 0;
		padding-top: 0;
	}
	&::before {
		@extend %position-set;
		background: var(--gradient-one);
		z-index: -1;
		width: calc(100% - 200px);
		left: auto;
		right: 0;
		height: calc(100% - 100px);
		@include breakpoint(max-sm) {
			display: none;
		}
	}
	.map {
		height: 100%;
		width: 100%;
		min-height: 400px;
	}
	.map2 {
		height: 100%;
		width: 100%;
		min-height: 400px;
		display: none;
	}
}

// Contact Section Starts
.contact-info-wrapper {
	padding: 30px;
	background: hsl(var(--light));
	border-radius: 10px;
	@include breakpoint(max-xs) {
		padding: 0px;
		background: transparent;
	}
}
.info-item {
	@extend %flex;
	.info-icon {
		img {
			max-width: 50px;
			max-height: 50px;
			object-fit: contain;
			@include breakpoint(max-xs) {
				max-width: 40px;
				max-height: 40px;
			}
		}
	}
	.info-content {
		width: calc(100% - 50px);
		padding-left: 25px;
		@include breakpoint(max-xs) {
			width: calc(100% - 40px);
		}
		.title {
			margin-bottom: 15px;
		}
		p {
			@include breakpoint(max-xs) {
				font-size: 15px;
			}
		}
	}
}
.error-wrapper {
	p {
		max-width: 600px;
		margin: 0 auto;
	}
}
.map-wrapper {
	max-width: 820px;
	margin: 0 auto;
	.map2 {
		display: none;
	}
}
// Contact Section Ends

// Faq Section Starts
.faq-item {
	padding: 30px;
	border-radius: 10px;
	background: hsl(var(--light));
	@extend %flex;
	@include breakpoint(max-xs) {
		padding: 20px;
	}
	&:not(:last-child) {
		margin-bottom: 40px;
	}
	.faq-title {
		width: 35%;
		padding-right: 30px;
		border-right: 2px solid hsl(var(--border));
		@include breakpoint(max-md) {
			width: 100%;
			padding-right: 0;
			border: 0;
		}
	}
	.faq-content {
		width: 65%;
		padding-left: 40px;
		@include breakpoint(max-md) {
			width: 100%;
			padding-left: 0;
			padding-top: 15px;
		}
	}
}
// Faq Section Ends

// Domain Section Starts
.domain-item {
	padding: 40px;
	border: 1px solid hsl(var(--border));
	text-align: center;
	@extend %transition;
	@include breakpoint(max-xxl) {
		padding: 35px 25px;
	}
	@include breakpoint(max-md) {
		padding: 35px 25px;
	}
	&:hover {
		background: hsl(var(--light));
	}
	.thumb {
		img {
			width: 120px;
			height: 120px;
			object-fit: contain;
			@include breakpoint(max-md) {
				width: 100px;
				height: 100px;
			}
		}
	}
	.title {
		font-size: 36px;
		font-weight: 500;
		margin: 25px 0 35px;
		@include breakpoint(max-md) {
			font-size: 34px;
			margin: 20px 0 30px;
		}
		sub {
			font-size: 50%;
			font-weight: 300;
			bottom: 0;
		}
	}
}
// Domain Section Ends

// Privacy Policy Section Starts
.policy-tabs {
	position: sticky;
	top: 100px;
	padding: 40px;
	@include breakpoint(max-xs) {
		padding: 20px;
	}
	li {
		padding: 7px 0;
		&:not(:last-child) {
			border-bottom: 1px solid hsl(var(--border));
		}
		a.active,
		&:hover {
			@include gradient-color(var(--gradient-one));
		}
		a {
			padding: 0;
			color: var(--body);
		}
	}
}
.policy-section {
	.custom--card:not(:last-child) {
		margin-bottom: 30px;
	}
}
// Privacy Policy Section Ends

.theme-switch-wrapper {
	line-height: 1;
}
.slider {
	position: relative;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 35px;
	border-radius: 5px;
	&::before {
		@extend %font-ico;
		content: "\f186";
		@extend %transform-center;
	}
}
input:checked + .slider:before {
	content: "\f185";
	color: hsl(var(--white)) !important;
}

.header-two {
	.slider {
		&::before {
			color: hsl(var(--white));
		}
	}
	input:checked + .slider:before {
		content: "\f185";
		color: hsl(var(--white)) !important;
	}
}

.header--dark {
	.slider {
		&::before {
			color: hsl(var(--white));
		}
	}
	input:checked + .slider:before {
		color: hsl(var(--white)) !important;
	}
	&.sticky {
		.slider {
			&::before {
				color: hsl(var(--dark));
			}
		}
	}
}

[data-theme="dark"] {
	.header--dark.sticky {
		.slider {
			&::before {
				color: hsl(var(--white)) !important;
			}
		}
		input:checked + .slider:before {
			color: hsl(var(--white)) !important;
		}
	}
}
