.custom--modal .modal--footer,
.custom--modal .modal--body,
.custom--modal .modal--header {
	border-color: var(--border) !important;
}

.custom--modal .modal--footer .modal-title,
.custom--modal .modal--header .modal-title {
	margin: 0;
	color: var(--heading);
}

.custom--modal .modal--footer .btn-close,
.custom--modal .modal--header .btn-close {
	background: transparent;
	font-size: 20px;
	line-height: 1;
	color: hsl(var(--danger)) !important;
}
