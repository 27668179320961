.banner-section {
	padding-top: 200px;
	@include breakpoint(max-xxl) {
		padding: 0;
		padding-top: 180px;
	}
	@include breakpoint(max-sm) {
		padding-top: 120px;
	}
	@include breakpoint(max-xs) {
		padding-bottom: 30px;
	}
}
.banner-wrapper {
	@extend %flex;
	align-items: center;
	gap: 50px 0;
}
.banner-content {
	max-width: 550px;
	@include breakpoint(max-xl) {
		max-width: 500px;
	}
	@include breakpoint(max-lg) {
		max-width: 600px;
		margin: 0 auto;
		text-align: center;
	}
	.subtitle {
		position: relative;
		padding-left: 40px;
		font-size: 18px;
		color: var(--heading);
		margin-bottom: 12px;
		&.text-white {
			&::before {
				background: hsl(var(--white));
			}
		}
		&::before {
			@extend %position;
			left: 0;
			top: 48%;
			height: 2px;
			width: 30px;
			background: var(--heading);
		}
	}
	.title {
		margin-bottom: 65px;
		@include breakpoint(max-xl) {
			margin-bottom: 45px;
		}
		@include breakpoint(max-md) {
			margin-bottom: 35px;
		}
		span {
			color: rgb(var(--base1));
		}
	}
	.video-button {
		transform: translateY(60%);
		@include breakpoint(max-xxl) {
			transform: translateY(25%);
		}
		@include breakpoint(max-lg) {
			transform: translateY(0);
			margin: 0 auto;
		}
	}
}
.banner-thumb {
	width: calc(100% - 550px);
	@include breakpoint(max-xl) {
		width: calc(100% - 500px);
	}
	@include breakpoint(max-lg) {
		max-width: 80%;
		width: 100%;
		margin: 0 auto;
	}
	img {
		max-width: 100%;
	}
}
.domain-search-form {
	.input-group:focus-within {
		border-color: rgb(var(--base2)) !important;
	}
	.form-group {
		@extend %flex;
		width: 100%;
	}
	.btn {
		width: 160px;
	}
	.input-group {
		max-width: calc(100% - 160px);
		width: 100%;
	}
	@include breakpoint(max-sm) {
		.input-group {
			max-width: 100%;
		}
		.btn {
			width: 100%;
			background: rgb(var(--base2));
			color: hsl(var(--white)) !important;
			border-radius: 0 0 3px 3px !important;
			margin-left: 0 !important;
		}
		select {
			padding-inline: 10px;
		}
	}
	.custom--select {
		width: auto !important;
	}
	&.style-two {
		.form-group:focus-within {
			border-color: rgb(var(--base1)) !important;
			border-radius: 6px;
		}
	}
}
.price-list {
	gap: 15px 40px;
	margin-top: 35px;
	@include breakpoint(max-lg) {
		justify-content: center;
	}
	@include breakpoint(max-xl) {
		gap: 15px 20px;
	}
	@include breakpoint(max-sm) {
		gap: 15px;
	}
	&.style-two {
		gap: 20px 24px;
		text-align: center;
		justify-content: center;
		@include breakpoint(max-xl) {
			gap: 15px;
			font-size: 15px;
		}
		@include breakpoint(max-md) {
			gap: 12px;
			font-size: 14px;
		}
		li {
			border: 1px solid hsl(var(--border));
			padding: 13px 22px;
			border-radius: 6px;
			background: hsl(var(--light));
			&.active {
				background: var(--gradient-one);
				color: hsl(var(--white));
			}
			span {
				display: block;
				line-height: 1.4;

				&.price {
					font-weight: 500;
				}
			}
		}
	}
}

.banner-section-two {
	padding-top: 150px;
	padding-bottom: 100px;
	position: relative;
	@include breakpoint(max-xxl) {
		padding-top: 200px;
		padding-bottom: 150px;
	}
	@include breakpoint(max-sm) {
		padding-top: 120px;
		padding-bottom: 90px;
	}
	&::before {
		@extend %position-set;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
		background: var(--gradient-one);
	}
	.container {
		position: relative;
		z-index: 1;
	}
}

// Inner Banner
.inner-banner {
	position: relative;
	padding: 130px 0;
	padding-top: 210px;
	@include breakpoint(max-xl) {
		padding-top: 150px;
		padding-bottom: 100px;
	}
	@include breakpoint(max-lg) {
		padding-top: 130px;
	}
	@include breakpoint(max-md) {
		padding-top: 110px;
		padding-bottom: 70px;
	}
	&::before {
		@extend %position-set;
		background: hsl(var(--accent) / 0.8);
	}
	.container {
		position: relative;
	}
	.title {
		@include breakpoint(max-sm) {
			font-size: 35px;
		}
	}
}
.breadcrumb {
	font-size: 18px;
	color: hsl(var(--white));
	gap: 10px 20px;
	a {
		color: hsl(var(--white));
	}
}
