.list {
	&.list-50 {
		@extend %flex;
		gap: 0 25px;
		li {
			width: 40%;
            @include breakpoint(max-sm) {
                width: 100%;
            }
		}
	}
	&.list-style-one {
		li {
			position: relative;
			padding: 6px 0;
			padding-left: 25px;
			&::before {
				@extend %font-ico;
				content: "\f00c";
				left: 0;
				top: 6px;
				color: rgb(var(--base1));
			}
		}
	}
	&.list-style-two {
		li {
			position: relative;
			padding: 6px 0;
			padding-left: 25px;
			&::before {
				@extend %position;
				width: 9px;
				height: 9px;
				background: var(--gradient-one);
				left: 0;
				top: 16px;
				border-radius: 50%;
			}
		}
	}
}
