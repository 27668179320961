// Padding Top
.pt-120 {
	padding-top: clamp(75px, 9vw, 120px);
}
.pt-100 {
	padding-top: clamp(55px, 6vw, 100px);
}
.pt-80 {
	padding-top: clamp(45px, 6vw, 80px);
}
.pt-60 {
	padding-top: clamp(40px, 4vw, 60px);
}
.pt-50 {
	padding-top: clamp(35px, 6vw, 50px);
}
.pt-40 {
	padding-top: clamp(30px, 6vw, 40px);
}

// Padding Bottom
.pb-120 {
	padding-bottom: clamp(75px, 9vw, 120px);
}
.pb-100 {
	padding-bottom: clamp(55px, 6vw, 100px);
}
.pb-80 {
	padding-bottom: clamp(45px, 6vw, 80px);
}
.pb-60 {
	padding-bottom: clamp(40px, 4vw, 60px);
}
.pb-50 {
	padding-bottom: clamp(35px, 6vw, 50px);
}
.pb-40 {
	padding-bottom: clamp(30px, 6vw, 40px);
}
