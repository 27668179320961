html {
	scroll-behavior: smooth;
}
body {
	padding: 0;
	margin: 0;
	font-size: 16px;
	color: var(--body);
	line-height: 1.9;
	font-family: var(--body-font);
	background-color: hsl(var(--body-bg));
}
p {
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none;
	color: var(--body);
	&:hover {
		color: rgb(var(--base1));
	}
}
a,
img,
.title,
.name {
	@extend %transition;
}
span,
sub,
sup,
a {
	display: inline-block;
}

@include breakpoint(max-sm) {
	.container,
	.container-max-lg {
		max-width: 450px !important;
	}
}
