.table {
	margin: 0;
	font-size: 15px;
	border-collapse: separate;
	border-spacing: 0 15px;
	@include breakpoint(max-xxl) {
		font-size: 14px;
	}
	thead {
		tr {
			color: hsl(var(--white));
			background: var(--gradient-one);
			th {
				padding: 15px;
				font-family: var(--heading);
				font-weight: 500;
				text-align: center;
				&:first-child {
					border-radius: 10px 0 0 0;
					text-align: left;
				}
				&:last-child {
					border-radius: 0 10px 0 0;
				}
			}
		}
	}
	tbody {
		tr {
			background: hsl(var(--light));
			border-radius: 10px;
			&:hover {
				background: hsl(var(--light-600));
			}
			td {
				vertical-align: middle;
				padding: 10px 15px;
				border-color: transparent;
				text-align: center;
				&:first-child {
					text-align: left;
				}
				&:last-child {
					text-align: right;
				}
			}
		}
	}
}
.table--responsive--md {
	@include breakpoint(max-md) {
		thead {
			display: none;
		}
		tbody {
			tr {
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 15px;
					border-bottom: 1px dashed hsl(var(--border));
					font-size: 14px !important;
					text-align: right;
					gap: 0 15px;
					&:last-child {
						border: none;
					}
					&::before {
						content: attr(data-label);
						font-family: var(--heading);
						font-size: 16px;
						color: var(--heading);
						font-weight: 500;
						text-align: left;
						@include breakpoint(max-sm) {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}
.table--responsive--lg {
	@include breakpoint(max-lg) {
		thead {
			display: none;
		}
		tbody {
			tr {
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 15px;
					border-bottom: 1px dashed hsl(var(--border));
					font-size: 14px !important;
					text-align: right;
					gap: 0 15px;
					&:last-child {
						border: none;
					}
					&::before {
						content: attr(data-label);
						font-family: var(--heading);
						font-size: 16px;
						color: var(--heading);
						font-weight: 500;
						text-align: left;
						@include breakpoint(max-sm) {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}
.table--responsive--xl {
	@include breakpoint(max-xl) {
		thead {
			display: none;
		}
		tbody {
			tr {
				td {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 15px;
					border-bottom: 1px dashed hsl(var(--border));
					font-size: 14px !important;
					text-align: right;
					gap: 0 15px;
					&:last-child {
						border: none;
					}
					&::before {
						content: attr(data-label);
						font-family: var(--heading);
						font-size: 16px;
						color: var(--heading);
						font-weight: 500;
						text-align: left;
						@include breakpoint(max-sm) {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}
