// Header Section Starts Here
.header {
	padding: 0 25px;
	padding-right: 0;
	z-index: 111;
	position: fixed;
	width: 100%;
	top: 0;
	@extend %transition;
	@include breakpoint(max-xxl) {
		padding: 0 25px;
	}
	@include breakpoint(max-xs) {
		padding: 0 15px;
	}
	&.sticky {
		background: hsl(var(--white));
		box-shadow: 0 3px 25px 5px rgb(var(--base2) / 0.1);
		.header-contact {
			padding: 15px 48px !important;
		}
	}
}
.header.header--dark {
	.menu > li > a {
		color: hsl(var(--white));
	}
	.btn {
		color: hsl(var(--white)) !important;
	}
	&.sticky {
		@include breakpoint(lg) {
			.menu > li > a {
				color: hsl(var(--body));
			}
			.btn {
				color: hsl(var(--body)) !important;
				&:hover {
					color: hsl(var(--white)) !important;
				}
			}
		}
	}
}
.header-bottom {
	padding: 15px 0;
	width: 100%;
	@include breakpoint(max-lg) {
		padding: 15px 0;
	}
}
.header-bottom-area {
	@extend %flex;
	justify-content: space-between;
	position: relative;
}
.logo {
	img {
		max-width: 200px;
		max-height: 60px;
		@include breakpoint(max-xl) {
			max-width: 180px;
		}
		@include breakpoint(max-md) {
			max-width: 170px;
		}
	}
}

// Menu
.menu {
	@extend %flex;
	align-items: center;
	margin: 0;
	position: relative;
	> .has-sub-menu {
		margin-right: 20px;
		@include breakpoint(max-xl) {
			margin-right: 18px;
		}
		&.open {
			.sub-menu {
				@include breakpoint(lg) {
					display: block !important;
				}
			}
		}
	}
	.sub-menu {
		li {
			width: 100%;
		}
		@include breakpoint(lg) {
			display: block !important;
		}
	}
	li {
		position: relative;
		&:hover > .sub-menu {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}
		&.has-sub-menu > a {
			position: relative;
			display: flex;
			justify-content: space-between;
			&::after {
				@extend %line-ico;
				content: "\f067";
				right: 0;
				top: 50%;
				transform: translate(8px, -50%);
				font-size: 14px;
				@extend %transition;
			}
			&:hover {
				&::after {
					content: "\f068";
				}
			}
		}
		a {
			display: block;
			padding: 4px 15px;
			font-weight: 600;
			position: relative;
			color: var(--heading);
			&:hover {
				color: rgb(var(--base2));
			}
			@include breakpoint(max-xl) {
				padding-inline: 10px;
				font-size: 15px;
			}
		}
		.btn {
			padding: 11px 35px;
			@include breakpoint(max-lg) {
				color: hsl(var(--white)) !important;
				padding: 8px 25px;
			}
		}
	}
	.btn-close {
		position: absolute;
		right: 20px;
		top: 20px;
	}
}

// Sub Menu
@include breakpoint(lg) {
	.sub-menu {
		position: absolute;
		top: 100%;
		left: 0px;
		opacity: 0;
		visibility: hidden;
		min-width: 200px;
		@include transition;
		transform: translateY(15px);
		z-index: 111;
		background: hsl(var(--white));
		padding: 10px;
		box-shadow: 0px 3px 8px 3px rgb(var(--base2) / 0.2);
		li {
			padding: 0;
			&:last-child {
				border: none;
			}
			a {
				font-size: 14px;
				padding: 7px 15px;
				display: block;
				@include text-wrap;
				-webkit-line-clamp: 1;
				&:hover {
					padding-left: 20px;
					background: rgb(var(--base2));
					color: hsl(var(--white)) !important;
				}
			}
		}
		&:hover {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}

		.sub-menu {
			visibility: hidden;
			opacity: 0;
			top: 5px;
			left: -100%;
			transform: translateY(-20px);
		}

		&.has-sub-menu > a {
			&::after {
				transform: translateY(0px);
			}
		}
	}
}

// Mobile Menu
@include breakpoint(max-lg) {
	.menu > li {
		&:nth-last-child(1) {
			border-bottom: none;
		}
		&:nth-last-child(2) {
			border-bottom: none;
		}
	}
	.menu {
		position: absolute;
		top: 0;
		left: 0;
		padding: 50px 30px 50px;
		max-height: calc(100vh - 50px);
		min-width: 200px;
		width: 100%;
		visibility: hidden;
		transform-origin: top;
		transform: translateY(-100px) scaleY(0.6);
		opacity: 0;
		overflow-y: auto;
		@include transition;
		background: var(--gradient-one);
		@include breakpoint(max-xs) {
			padding: 25px 20px;
		}
		&.active {
			opacity: 1;
			transform: translateY(0) scaleY(1);
			visibility: visible;
			z-index: 999;
			top: 0px;
		}
		.has-sub-menu {
			margin-right: 0;
		}
		.has-sub-menu > a {
			&::after {
				transform: translate(-20px, -50%) !important;
			}
		}
		li {
			padding: 3px 0;
			width: 100%;
			border-bottom: 1px solid hsl(var(--white) / 0.2);
			&:nth-last-child(1) {
				border-bottom: none;
			}
			a {
				padding-left: 0;
				color: hsl(var(--white));
			}
			&.has-sub-menu {
				a {
					&::after {
						transform: translate(0px, -50%) !important;
					}
				}
			}
		}
	}
	.sub-menu {
		display: none;
		padding-left: 25px;
	}
}

// Trigger
.header-trigger {
	cursor: pointer;
	margin-top: -10px;
	&.active {
		span {
			background: none !important;
			&::before {
				transform: rotate(-45deg) translate(-11px, 0px);
				background: rgb(var(--base2));
			}
			&::after {
				transform: rotate(45deg) translate(-11px, 0px);
				background: rgb(var(--base2));
			}
		}
	}
	span {
		width: 25px;
		height: 2px;
		background: rgb(var(--base2));
		position: relative;
		transition: all ease 0.3s;
		&::after,
		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			left: 0;
			background: rgb(var(--base2));
			@extend %transition;
		}
		&::after {
			bottom: -8px;
		}
		&::before {
			top: -8px;
		}
	}
}

.header-contact {
	background: var(--gradient-one);
	color: hsl(var(--white)) !important;
	position: absolute;
	right: 0;
	top: 50%;
	@include breakpoint(max-xxl) {
		padding: 30px;
	}
}

// Header Two
.header-two {
	position: fixed;
	top: 25px;
	width: 100%;
	z-index: 111;
	@extend %transition;
	@include breakpoint(max-sm) {
		top: 15px;
	}
	@include breakpoint(max-xs) {
		top: 10px;
	}
	&.sticky {
		background: hsl(var(--accent));
		top: 0;
	}
	.header-bottom {
		background: hsl(var(--accent));
		@include breakpoint(lg) {
			padding-right: 25px;
		}
	}
	.logo {
		height: 100%;
		padding: 20px 30px;
		background: #000;
		@include breakpoint(max-xl) {
			padding: 15px 20px;
		}
		@include breakpoint(max-xs) {
			padding: 15px;
			padding-right: 0;
			background: transparent;
		}
	}
	.menu > li > a {
		color: hsl(var(--white));
	}
	.btn {
		color: hsl(var(--white)) !important;
	}
}
