.pagination {
	@extend %flex;
	justify-content: center;
	gap: 12px;
	.page-item {
		text-align: center;
		a,
		span {
			font-weight: 600;
			width: 45px;
			height: 45px;
			color: var(--body);
			line-height: 45px;
			padding: 0;
			border-radius: 50% !important;
			border-color: hsl(var(--border));
			box-shadow: none;
		}
		&.active,
		&:hover {
			span,
			a {
				background: var(--gradient-one);
				color: hsl(var(--white));
				border-color: transparent;
			}
		}
		&.disabled {
			cursor: no-drop !important;
			span,
			a {
				color: var(--body) !important;
				background: hsl(var(--dark)/.1);
				border: none;
			}
		}
	}
}
