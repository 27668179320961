.custom--card {
	border: 1px solid transparent;
	border-radius: 5px;
}

.custom--card .card-header,
.custom--card .card-footer {
	text-align: center;
}

.custom--card .card-header,
.custom--card .card-footer {
	background: transparent;
}

.custom--card .card-header,
.custom--card .card-footer {
	padding: 15px 25px;
	border-color: transparent !important;
	@include breakpoint(max-sm) {
		padding: 10px 20px;
	}
	@include breakpoint(max-sm) {
		padding: 8px 15px;
	}
}
.custom--card .card-body {
	border-radius: 10px;
	padding: 25px;
	background: hsl(var(--light));
	@include breakpoint(max-sm) {
		padding: 20px;
	}
	@include breakpoint(max-xs) {
		padding: 15px;
	}
}

.custom--card.card--lg .card-header,
.custom--card.card--lg .card-footer {
	padding: 20px 35px;
	@include breakpoint(max-md) {
		padding: 15px 25px;
	}
	@include breakpoint(max-sm) {
		padding: 10px 20px;
	}
	@include breakpoint(max-xs) {
		padding: 8px 15px;
	}
}
.custom--card.card--lg .card-body {
	padding: 35px;
	@include breakpoint(max-md) {
		padding: 25px;
	}
	@include breakpoint(max-sm) {
		padding: 20px;
	}
	@include breakpoint(max-xs) {
		padding: 15px;
	}
}

.custom--card.card--md .card-header,
.custom--card.card--md .card-footer {
	padding: 10px 20px;
}
.custom--card.card--md .card-body {
	padding: 20px 20px;
}
