.sidebar {
	padding: 40px;
	background: hsl(var(--light));
    @include breakpoint(max-xxl) {
        padding: 25px;
    }
    @include breakpoint(max-xl) {
        @include breakpoint(lg) {
            padding: 0px;
            background: transparent;
        }
        @include breakpoint(max-sm) {
            padding: 0px;
            background: transparent;
        }
    }
}

.sidebar .sidebar-item {
    padding: 15px;
    margin: 15px 0;
	background: hsl(var(--white));
}

@media (max-width: 575px) {
	.sidebar .sidebar-item {
		padding: 35px 20px;
        margin: 25px 0;
	}
}

.sidebar .sidebar-item:last-child {
	margin-bottom: 0;
}

.sidebar .sidebar-item > .title {
	margin-bottom: 25px;
    padding-bottom: 10px;
    position: relative;
    &::before {
        position: absolute;
        content: '';
        width: 55px;
        height: 3px;
        background: var(--gradient-one);
        border-radius: 5px;
        bottom: 0;
        left: 0;

    }
    &::after {
        position: absolute;
        content: '';
        width: 6px;
        height: 3px;
        background: hsl(var(--white));
        border-radius: 5px;
        bottom: 0;
        left: 5px;

    }
}

@media (max-width: 575px) {
	.sidebar .sidebar-item {
		padding: 0;
	}
}

.lastest-post-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid hsl(var(--border));
}

.lastest-post-item:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border: 0;
}
.lastest-post-item .thumb {
	width: 80px;
	height: 70px;
}

@media (max-width: 575px) {
	.lastest-post-item .thumb {
		width: 60px;
		height: 60px;
	}
}

.lastest-post-item .thumb img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.lastest-post-item .content {
	width: calc(100% - 80px);
	padding-left: 15px;
}

.lastest-post-item .content .title {
	font-size: 16px !important;
}

@media (max-width: 575px) {
	.lastest-post-item .content .title {
		margin-bottom: 5px;
	}
}

.lastest-post-item .content .title a {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
}
.lastest-post-item .content .meta-post {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 5px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.lastest-post-item .content .meta-post li {
	padding: 1px 0;
	font-size: 13px;
	font-style: italic;
}

.category-wrapper ul .category-item {
	width: 100%;
	padding: 10px 0;
	border-bottom: 1px solid hsl(var(--border));
}

.category-wrapper ul .category-item:last-child {
	border: 0;
}

.category-wrapper ul .category-item a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-weight: 500;
}


.tags {
	a {
		position: relative;
		border: 1px solid hsl(var(--border));
		color: var(--body);
		&.active, &:hover {
			color: hsl(var(--white));
			&::before {
				opacity: 1;
			}
		}
		&::before {
			border-radius: 5px;
			@extend %position-set;
			background: var(--gradient-one);
			@extend %transition;
			z-index: -1;
			opacity: 0;
		}
	}
}
