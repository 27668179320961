/*
=================================
        Table of Content
=================================

01. Reset CSS Start
---------------------------
02. Typography CSS
---------------------------
03. Preloader CSS
---------------------------
04. Section Header CSS
---------------------------
05. Features Section CSS
---------------------------
06. Statistic Section CSS
---------------------------
07. Overview Section CSS
---------------------------
08. Plan Section CSS
---------------------------
09. Plan Section Two CSS
---------------------------
10. Plan Overview Section CSS
---------------------------
11. Team Section CSS
---------------------------
12. Why Choose Section CSS
---------------------------
13. Testimonial Section CSS
---------------------------
14. Blog Section Three CSS
---------------------------
15. Domain Section CSS
---------------------------
16. Brand Section CSS
---------------------------
17. Contact Section CSS
---------------------------
18. FAQ Section CSS
---------------------------
19. Privacy Policy Section CSS
---------------------------
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

:root {
	// Fonts
	--heading-font: "Poppins", sans-serif;
	--body-font: "Roboto", sans-serif;

	//Colors
	--heading: #212121;
	--body: #757575;
	--border: 0 0% 90%;

	--base1: 187 41 110;
	--base2: 7 158 207;
	--base3: 191 5 199;

	--color1: #f9636b;
	--color2: #b6246e;
	--color3: #01c8ef;
	--color4: #172f78;
	--color5: #ea2391;
	--color6: #9c07a2;

	--gradient-one: radial-gradient(108.3% 186.75% at -4.62% 9.78%, var(--color1) 0%, var(--color2) 100%);
	--gradient-two: linear-gradient(90deg, var(--color3) -6.06%, var(--color4) 64.49%);
	--gradient-three: linear-gradient(90deg, var(--color5) -6.06%, var(--color6) 64.49%);

	// Accent Color Shade
	--accent-h: 260;
	--accent-s: 92%;
	--accent-l: 10%;
	--accent-50: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 25%);
	--accent-100: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 20%);
	--accent-200: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 15%);
	--accent-300: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 10%);
	--accent-400: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 5%);
	--accent: var(--accent-h) var(--accent-s) var(--accent-l);
	--accent-600: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 5%);
	--accent-700: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 10%);
	--accent-800: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 15%);
	--accent-900: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 20%);

	--primary: 245 82% 67%;
	--secondary: 210 7% 56%;
	--success: 147 67% 47%;
	--danger: 360 78% 62%;
	--warning: 29 100% 63%;
	--info: 203 89% 53%;
	--dark: 206 70% 11%;
	--white: 0 0% 100%;

	// Light Color Shade
	--light-h: 0;
	--light-s: 0%;
	--light-l: 98%;
	--light-50: var(--light-h) var(--light-s) calc(var(--light-l) + 33%);
	--light-100: var(--light-h) var(--light-s) calc(var(--light-l) + 20%);
	--light-200: var(--light-h) var(--light-s) calc(var(--light-l) + 15%);
	--light-300: var(--light-h) var(--light-s) calc(var(--light-l) + 10%);
	--light-400: var(--light-h) var(--light-s) calc(var(--light-l) + 2%);
	--light: var(--light-h) var(--light-s) var(--light-l);
	--light-600: var(--light-h) var(--light-s) calc(var(--light-l) - 2%);
	--light-700: var(--light-h) var(--light-s) calc(var(--light-l) - 10%);
	--light-800: var(--light-h) var(--light-s) calc(var(--light-l) - 15%);
	--light-900: var(--light-h) var(--light-s) calc(var(--light-l) - 20%);
}

[data-theme="dark"] {
	//Colors
	--heading: #fff;
	--body: #fff;
	--border: 0 0% 90%;

	--body-bg: 260 92% 10%;
	--section-bg: 260 91% 6%;
	--light: 260 91% 6%;

	--base1: 187 41 110;
	--base2: 7 158 207;
	--base3: 191 5 199;

	// Accent Color Shade
	--accent-h: 260;
	--accent-s: 92%;
	--accent-l: 10%;
	--accent-50: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 25%);
	--accent-100: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 20%);
	--accent-200: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 15%);
	--accent-300: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 10%);
	--accent-400: var(--accent-h) var(--accent-s) calc(var(--accent-l) + 5%);
	--accent: var(--accent-h) var(--accent-s) var(--accent-l);
	--accent-600: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 5%);
	--accent-700: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 10%);
	--accent-800: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 15%);
	--accent-900: var(--accent-h) var(--accent-s) calc(var(--accent-l) - 20%);

	// Light Color Shade
	--light-h: 260;
	--light-s: 51%;
	--light-l: 19%;
	--light-50: var(--light-h) var(--light-s) calc(var(--light-l) + 33%);
	--light-100: var(--light-h) var(--light-s) calc(var(--light-l) + 20%);
	--light-200: var(--light-h) var(--light-s) calc(var(--light-l) + 15%);
	--light-300: var(--light-h) var(--light-s) calc(var(--light-l) + 10%);
	--light-400: var(--light-h) var(--light-s) calc(var(--light-l) + 5%);
	--light: var(--light-h) var(--light-s) var(--light-l);
	--light-600: var(--light-h) var(--light-s) calc(var(--light-l) - 5%);
	--light-700: var(--light-h) var(--light-s) calc(var(--light-l) - 10%);
	--light-800: var(--light-h) var(--light-s) calc(var(--light-l) - 15%);
	--light-900: var(--light-h) var(--light-s) calc(var(--light-l) - 20%);
}
