.custom-container {
	padding: 0 30px;
	@include breakpoint(max-xl) {
		padding: 0 15px;
	}
}

.dashboard-sidebar {
	width: 300px;
	background: hsl(var(--light));
	padding: 35px 30px;
	border-radius: 10px;

	@include breakpoint(max-xl) {
		position: fixed;
		top: 0px;
		left: 0;
		border-radius: 0 10px 10px 0;
		min-height: 100vh;
		overflow-y: auto;
		background: hsl(var(--white));
		box-shadow: 3px 0 15px hsl(var(--dark) / 0.1);
		transform: translateX(-100%);
		transition: all ease-in 0.4s;
		z-index: 1111;
		&.active {
			transform: translateX(0);
		}
	}
}

.fs--14px {
	font-size: 14px;
}
.fs--12px {
	font-size: 12px;
}

.dashboard-menu {
	li {
		&:not(:last-child) {
			border-bottom: 1px solid hsl(var(--border));
		}
		a {
			padding: 7px 0;
			display: block;
		}
		a.active,
		a:hover {
			@include gradient-color(var(--gradient-one));
			.badge {
				-webkit-text-fill-color: hsl(var(--white)) !important;
			}
		}
		i {
			margin-right: 5px;
			font-size: 17px;
		}
	}
}

.dashboard-body {
	width: calc(100% - 300px);
	padding-left: 40px;
	@include breakpoint(max-xl) {
		width: calc(100% - 250px);
		padding-left: 30px;
	}
	@include breakpoint(max-xl) {
		width: 100%;
		padding-left: 0px;
	}
	.dashboard-inner {
		padding: 25px 40px 50px;
		border-radius: 10px;
		@include breakpoint(max-md) {
			padding: 20px 30px 40px;
		}
		@include breakpoint(max-sm) {
			background: hsl(var(--white)) !important;
			padding: 0;
		}
	}
}
.notification {
	position: relative;
	.badge {
		position: absolute;
		padding-inline: 6px;
		top: -3px;
		left: calc(100% - 10px);
	}
	i {
		font-size: 26px;
	}
}
.profile-menu {
	.thumb {
		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
		}
	}
}
.search-bar {
	.form-group {
		position: relative;
	}
	button {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
	}
	.form--control {
		border-radius: 35px;
		padding-left: 45px;
	}
	@include breakpoint(max-xs) {
		width: 180px;
	}
}

.dashboard-toggler-wrapper {
	.toggler {
		font-size: 24px;
	}
}
