.btn,
.cmn--btn {
	padding: 10px 35px;
	font-weight: 500;
	outline: none !important;
	box-shadow: none !important;
	@extend %transition;
	border-radius: 5px;
	z-index: 1;
	&.btn--lg {
		padding: 12px 35px !important;
		font-size: 18px;
		@include breakpoint(max-lg) {
			font-size: 16px;
		}
	}
	&.btn--md {
		padding: 8px 20px !important;
		font-size: 15px;
	}
	&.btn--sm {
		padding: 5px 15px !important;
		font-size: 14px;
	}
	&.btn--xs {
		padding: 0px 10px !important;
		font-size: 13px;
	}
}

.cmn--btn {
	border: 0;
	background: var(--gradient-one);
	color: hsl(var(--white));
}

$colors: "primary" hsl(var(--primary)), "secondary" hsl(var(--secondary)), "success" hsl(var(--success)), "danger" hsl(var(--danger)), "warning" hsl(var(--warning)), "info" var(--info), "light" hsl(var(--light)), "dark" hsl(var(--dark)), "base1" rgb(var(--base1));

@each $name, $color in $colors {
	.btn--#{$name} {
		background-color: $color !important;
		border: 2px solid $color !important;
		color: hsl(var(--white)) !important;
	}
}
.btn--light {
	color: rgb(var(--base1));
}

@each $name, $color in $colors {
	.btn--outline-#{$name} {
		background-color: transparent !important;
		border: 2px solid $color !important;
		color: $color !important;
		&:hover, &.active {
			color: hsl(var(--white)) !important;
			border-color: rgb(var(--base1)) !important;
			background: rgb(var(--base1)) !important;
		}
	}
}
.border-1 {
	border-width: 1px !important;
}

.btn--outline-light {
	color: var(--body) !important;
	background: hsl(var(--white));
	border: 2px solid hsl(var(--border)) !important;
}

.video-button {
	position: relative;
	width: 110px;
	height: 110px;
	display: grid;
	place-items: center;
	font-size: 24px;
	border-radius: 50%;
	border: 10px solid hsl(var(--white));
	@include breakpoint(max-md) {
		height: 90px;
		width: 90px;
		border-width: 6px;
	}
	@include breakpoint(max-xs) {
		height: 70px;
		width: 70px;
		border-width: 6px;
		font-size: 20px;
	}
	&::before, &::after {
		@extend %position-set;
		background: var(--gradient-two);
		border-radius: 50%;
		animation: rotate 10s linear infinite;
	}
	i {
		color: hsl(var(--white));
		position: relative;
		z-index: 1;
	}
}