.text--primary {
	color: hsl(var(--primary)) !important;
}
.text--secondary {
	color: hsl(var(--secondary)) !important;
}
.text--success {
	color: hsl(var(--success)) !important;
}
.text--danger {
	color: hsl(var(--danger)) !important;
}
.text--warning {
	color: hsl(var(--warning)) !important;
}
.text--info {
	color: hsl(var(--info)) !important;
}
.text--dark {
	color: hsl(var(--dark)) !important;
}
.text--muted {
	color: hsl(var(--muted)) !important;
}
.text--base1 {
	color: rgb(var(--base1)) !important;
}
.text--base2 {
	color: rgb(var(--base2)) !important;
}
.text--body {
	color: var(--body) !important;
}
