/* background color css start */
.bg--primary {
	background-color: hsl(var(--primary)) !important;
}
.bg--secondary {
	background-color: hsl(var(--secondary)) !important;
}
.bg--success {
	background-color: hsl(var(--success)) !important;
}
.bg--danger {
	background-color: hsl(var(--danger)) !important;
}
.bg--warning {
	background-color: hsl(var(--warning)) !important;
}
.bg--info {
	background-color: hsl(var(--info)) !important;
}
.bg--dark {
	background-color: hsl(var(--dark)) !important;
}
.bg--light {
	background-color: hsl(var(--light)) !important;
}

.bg--gradient-one {
	background: var(--gradient-one) !important;
}
.bg--gradient-two {
	background: var(--gradient-two) !important;
}
.bg--gradient-three {
	background: var(--gradient-three) !important;
}
/* background color css end */
