h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 700;
	line-height: 1.3;
	color: var(--heading);
	font-family: var(--heading-font);
	word-break: break-word;
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
	color: var(--heading);
	word-break: break-word;
}
h1 {
	font-size: 70px;
	@include breakpoint(max-xl) {
		font-size: 58px;
	}
	@include breakpoint(max-lg) {
		font-size: 48px;
	}
	@include breakpoint(max-md) {
		font-size: 42px;
	}
	@include breakpoint(max-sm) {
		font-size: 40px;
	}
	@include breakpoint(max-xs) {
		font-size: 36px;
	}
}
h2 {
	font-size: 50px;
	@include breakpoint(max-xxl) {
		font-size: 42px;
	}
	@include breakpoint(max-xl) {
		font-size: 40px;
	}
	@include breakpoint(max-sm) {
		font-size: 36px;
	}
	@include breakpoint(max-xs) {
		font-size: 32px;
	}
}

h3 {
	font-size: 30px;
	@include breakpoint(max-xxl) {
		font-size: 26px;
	}
	@include breakpoint(max-md) {
		font-size: 24px;
	}
	@include breakpoint(max-xs) {
		font-size: 23px;
	}
}
h4 {
	font-size: 24px;
	@include breakpoint(max-xl) {
		font-size: 21px;
	}
	@include breakpoint(max-xs) {
		font-size: 19px;
	}
}
h5 {
	font-size: 20px;
	@include breakpoint(max-xxl) {
		font-size: 19px;
	}
	@include breakpoint(max-xs) {
		font-size: 18px;
	}
}
h6 {
	font-size: 18px;
}
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

.fw-medium {
	font-weight: 500 !important;
}
