[data-theme="dark"] {
	.header.sticky {
		background-color: hsl(var(--accent));
	}
	.social-links {
		li {
			a {
				color: rgb(var(--base1));
			}
		}
	}
	.plan-tab li a.active {
		background-color: hsl(var(--light-400));
		border-color: hsl(var(--light-400));
	}
	.sub-menu {
		@include breakpoint(lg) {
			background-color: hsl(var(--accent-600));
		}
	}
	.form--control {
		background: hsl(var(--accent));
		color: hsl(var(--white));
	}
	.feature-item:hover {
		background-color: hsl(var(--light-400));
	}
	.feature-item.style-two .feature-item__icon {
		border-color: hsl(var(--accent));
	}
	.table {
		color: hsl(var(--white));
		th {
			border: 0;
		}
		tr {
			&:hover {
				background-color: hsl(var(--light-400));
			}
		}
	}
	.bg-white {
		background-color: hsl(var(--accent)) !important;
	}
	.dashboard-menu li:not(:last-child),
	.category-wrapper ul .category-item,
	.lastest-post-item,
	.footer-bottom,
	.domain-item,
	.form--control,
	.form-group,
	.price-list.style-two li,
	.plan-item-three.style-two .plan-item-three__footer,
	.faq-item .faq-title,
	.policy-tabs li:not(:last-child) {
		border-color: hsl(var(--white) / 0.2) !important;
		&:focus {
			border-color: rgb(var(--base1)) !important;
		}
	}
	.sidebar .sidebar-item {
		background: transparent;
	}
	select.form--control {
		color: hsl(var(--white));
	}
	.map-wrapper {
		max-width: 820px;
		margin: 0 auto;
		.map2 {
			display: block;
		}
		.map {
			display: none;
		}
	}
	.swiper-pagination-bullet {
		background: rgb(var(--base1));
	}
	.brand-item {
		background: hsl(var(--light));
	}
	.pagination .page-item a,
	.pagination .page-item span {
		background: hsl(var(--light));
		border-color: hsl(var(--light));
		&:hover {
			background: rgb(var(--base1));
		}
	}
	.pagination .page-item.active a,
	.pagination .page-item.active span {
		background: rgb(var(--base1));
	}
	.tags a {
		&:hover {
			border-color: transparent;
		}
	}
	.dashboard-sidebar {
		background: hsl(var(--light));
	}
	.table tbody tr td {
		@include breakpoint(max-lg) {
			border-color: hsl(var(--white) / 0.3);
		}
	}
	.cmn--tabs li a {
		border-color: hsl(var(--white) / 0.3);
	}
}
