.form-label {
	font-size: 15px;
}
.form--control {
	height: 50px;
	border-radius: 5px;
	border: 1px solid hsl(var(--border));
	outline: 0 !important;
	box-shadow: none !important;
	&:focus {
		color: var(--white);
		border: 1px solid rgb(var(--base1));
	}
}
textarea.form--control {
	height: 130px;
}
.h-60 {
	height: 60px;
	@include breakpoint(max-sm) {
		height: 55px;
	}
}
.custom--select {
	position: relative;
	background: hsl(var(--light)) !important;
	z-index: 2;
	.arrow {
		position: absolute;
		right: 0;
		height: 100%;
		top: 0;
		padding: 5px;
		padding-right: 15px;
		pointer-events: none;
		display: grid;
		place-items: center;
		font-size: 13px;
	}
	select {
		padding-inline: 15px;
		appearance: none;
		padding-right: 35px !important;
	}
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: rgb(var(--heading));
	transition: background-color 5000s ease-in-out 0s;
}

.custom--radio {
	position: relative;
	padding-left: 0;
	input[type="radio"] {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
		cursor: pointer;
		&:checked {
			~ label {
				&::before {
					border-width: 2px;
					border-color: var(--base) !important;
				}
				&::after {
					opacity: 1;
				}
			}
		}
	}
	label {
		margin-bottom: 0;
		position: relative;
		padding-left: 20px;
		font-size: rem(14px);
		font-weight: 400;
		&::before {
			position: absolute;
			content: "";
			top: 4px;
			left: 0;
			width: 15px;
			height: 15px;
			border: 1px solid #888888;
		}
		&::after {
			position: absolute;
			content: "";
			top: 8px;
			left: 4px;
			width: 7px;
			height: 7px;
			background-color: var(--base) !important;
			opacity: 0;
		}
	}
}
.custom--checkbox {
	padding-left: rem(25px);
	input {
		display: none;
		&:checked {
			~ label {
				&::before {
					content: "\f14a";
					color: var(--base);
				}
			}
		}
	}
	label {
		position: relative;
		font-size: rem(15px);
		font-weight: 400;
		cursor: pointer;
		margin-bottom: 0;
		&::before {
			position: absolute;
			content: "\f04d";
			font-family: "Line Awesome Free";
			font-weight: 900;
			top: 3px;
			left: rem(-25px);
			font-size: rem(20px);
			line-height: 1;
			@extend %transition;
		}
	}
}
/* form css end*/
