.footer {
	position: relative;
	overflow: hidden;
	&::before {
		@extend %position-set;
		width: 1920px;
		height: 1920px;
		border-radius: 50%;
		background: rgb(var(--base2) / 0.03);
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
	}
	.shape {
		position: absolute;
		top: 0;
		opacity: 0.04;
		z-index: -1;
		max-width: 100%;
		img {
			max-width: 100%;
		}
		&.shape1 {
			left: 0;
		}
		&.shape2 {
			right: 0;
		}
	}
	.container {
		z-index: 1;
	}
}
.footer-top {
	border-bottom: 1px solid hsl(var(--dark) / 0.1);
}
.footer-bottom {
	border-bottom: 1px solid hsl(var(--dark) / 0.1);
	position: relative;
	z-index: 1;
	&::before {
		position: absolute;
		content: "";
		background: url(./../images/bg/bg1.png);
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		opacity: 0.08;
		z-index: -1;
	}
}

.footer-widget {
	max-width: 300px;
	.widget-title {
		margin-bottom: 15px;
	}
	.links {
		li {
			padding: 4px 0;
			a {
				letter-spacing: 0.5px;
			}
		}
	}
}
.footer-two {
	.footer-bottom {
		&::before {
			background: url(./../images/thumb/map.png);
			opacity: 1;
		}
	}
	.shape {
		display: none;
		@include min(1850px) {
			position: absolute;
			display: block;
			&.shape1 {
				left: 20px;
			}
			&.shape2 {
				right: 50px;
			}
		}
	}
}